import axios from 'axios'
// import { ElMessage } from 'element-plus'
import router from '../router'

// create an axios instance
const request = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? '/api' : 'http://api.library.jingshangw.com/',
})



request.interceptors.request.use((config) => {
  const auth = localStorage.getItem('accessToken')
  if (auth) {
    config.headers['accessToken'] = auth
  }
	return config
})

// response interceptor
request.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    console.log('response => ', response)
    if (res.code === '403' || res.code === '401') {
      localStorage.removeItem('uname');
      // ElMessage({
      //   message: res.error,
      //   type: 'error',
      //   duration: 1.5 * 1000
      // })
      return router.push('/login')
      // return location.reload()
      
    } else if (res.status === 'error') {
      // ElMessage({
      //   message: res.error || res.status,
      //   type: 'error',
      //   duration: 1.5 * 1000
      // })
    } else if (res.code !== '200') {
      // ElMessage({
      //   message: res.message || res.code,
      //   type: 'error',
      //   duration: 1.5 * 1000
      // })
    }

    
    return res
  },
  ({ message, response }) => {
    console.log('err => ', message, response) // for debug
    if (response.data && response.data.detail) {
      // ElMessage({
      //   message: response.data.detail,
      //   type: 'error',
      //   duration: 2 * 1000
      // })
    } else {
      // ElMessage({
      //   message: message,
      //   type: 'error',
      //   duration: 2 * 1000
      // })
    }
    if (response.status === 403 || response.status === 401) {
      localStorage.removeItem('user');
      router.push('/login')
    }
    return Promise.reject(message)
  }
)

export async function login(data){
  return request({
    url: '/user/auth/login',
    method: 'post',
    data
  })
}

export function getCategory (data) {
  return request({
    url: '/category/list',
    method: 'post',
    data
  })
}

export function getVideoList (data) {
  return request({
    url: 'videos/list',
    method: 'post',
    data
  })
}

export function getVideoDetail (id) {
  return request({
    url: `/videos/${id}`,
    // method: 'post'
  })
}

export function getVideoPage (data) {
  return request({
    url: '/category/video/list',
    method: 'post',
    data
  })
}


export function getVideoParts (params) {
  const id = params.id
  delete params.id
  return request({
    url: `/videos/${id}/parts`,
    params
  })
}

export function getBanners (params) {
  return request({
    url: '/banner/list',
    params
  })
}

export function getNewsList (data) {
  return request({
    url: '/article/list',
    method: 'post',
    data
  })
}

export function getNewsDetail (id) {
  return request({
    url: `/article/${id}`
  })
}


export function goodRecommend () {
  return request({
    url: 'index/recommends?type=VIDEO'
  })
}

export function popularGet (params) {
  return request({
    url: 'popular/query',
    params
  })
}

export function categoryRec () {
  return request({
    url: `index/category_rec`
  })
}

export function comments (id) {
  return request({
    url: `/video/comment?video_id=${id}`
  })
}

export function comment (data) {
  return request({
    url: `/video/comment/save`,
    data,
    method: 'post'
  })
}

export function checkLike (id) {
  return request({
    url: `video_collect/${id}/check`
  })
}

export function like (data) {
  return request({
    url: `video_collect/save`,
    method: 'post',
    data
  })
}

export function unlike (id) {
  return request({
    url: `video_collect`,
    method: 'delete',
    data: {
      videoId: id
    }
  })
}

export function starts (id) {
  return request({
    url: `comment_star/query?mediaId=${id}`,
  })
}

export function start (data) {
  return request({
    url: `comment_star/add`,
    data,
    method: 'post'
  })
}

export function advert () {
  return request({
    url: `advert`
  })
}

export function categoryBanner (id) {
  return request({
    url: `banner?categoryId=${id}`
  })
}

export function categoryRecNew (id) {
  return request({
    url: `category/${id}/rec`
  })
}

export function timeMeter (params) {
  return request({
    url: `time_meter`,
    params
  })
}

