import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/index'),
    name: 'index'
  },
  {
    path: '/videos/:fid?/:sid?',
    component: () => import('@/views/medias'),
    name: 'videos'
  },
  {
    path: '/video/:id/',
    component: () => import('@/views/video'),
    name: 'video'
  },
  {
    path: '/audios/:fid?/:sid?',
    component: () => import('@/views/medias'),
    name: 'audios'
  },
  {
    path: '/audio/:id/',
    component: () => import('@/views/audio'),
    name: 'audio'
  },
  {
    path: '/search/:keyWord',
    component: () => import('@/views/search'),
    name: 'search'
  },
  {
    path: '/articles',
    component: () => import('@/views/articles'),
    name: 'articles'
  },
  {
    path: '/article/:id',
    component: () => import('@/views/article'),
    name: 'article'
  },
  {
    path: '/about',
    component: () => import('@/views/about'),
    name: 'about'
  },
  {
    path: '/contact',
    component: () => import('@/views/contact'),
    name: 'contact'
  },
  // {
  //   path: '/login',
  //   component: () => import('@/views/login')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0,0)
  next()
});

export default router
