<template>
  <footer>
    <section class="container inside">
      <div class="contact">
        <img class="logo-img" src="../assets/pic/foot-logo.jpg" alt="">
      </div>
      <div class="cooperation">
        <p @click="$router.push('/contact')">联系我们</p>
        <p>
          <a target="_blank" href="http://www.jingshangw.com">官方网站</a>
        </p>
        <!-- <img class="qr-img" src="../assets/pic/qr.jpg" alt=""> -->
        <p class="qr-wrapper">
          <span>关注我们</span>
          <img class="hover-img" src="../assets/pic/qr.jpg" alt="">
        </p>
      </div>
      <div class="info">
        <!-- <p class="title">版权所有：北京京商文化传播有限责任公司 京ICP备12026138号-1</p> -->
        <div class="info-label">
          <p>邮箱：centvguoxue@sina.com</p>
          <p>Copyright © 2020 京商 版权所有</p>
          <p>电话：（010）67319669   87315021</p>
          <p>ICP备案号：京ICP备12026138号-1</p>
          <p>地址：北京市朝阳区南新园西路香榭舍21层</p> 
          <p>广播电视节目制作经营许可证：（京）字第06194号</p>
          <!-- <p>网址：<a target="_blank" href="http://guoxue.centv.cn">http://guoxue.centv.cn</a></p> -->
          
          <p>中国教育网络电视台：<a target="_blank" href="http://www.cetv.cn">http://www.cetv.cn</a></p>
          <p>出版物经营许可证：新出发京零 字第 朝200166号</p>
          <!-- <p>京商机构官网：<a target="_blank" href="http://www.jingshangw.com">http://www.jingshangw.com</a></p> -->
        </div>
      </div>
      
    </section>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      window
    };
  }
}
</script>
<style scoped>
.inside {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  font-family: SourceHanSansCN-Medium;
}
.inside a {
  color: inherit;
  text-decoration: none;
}
.xcx {
  width: 80px;
}
.contact {
  width: 200px;
}
.contact .logo-img{
  width: 175px;
  margin-top: 25px;
}
.contact p {
  font-size: 14px;
}
.cooperation {
  margin: 0 20px;
  color: #323232;
  margin-top: 15px;
  font-family: SourceHanSansCN-Bold;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
}
.cooperation p {
  cursor: pointer;
}
.cooperation .qr-img {
  width: 95px
}
.info {
  flex: 1;
  line-height: 39px;
  font-size: 14px;
  margin-top: 15px;
  margin-left: 20px;
}
.title {
  font-family: SourceHanSansCN-Bold;
  font-size: 15px;
  margin-bottom: 25px;
}
.info-label {
  display: flex;
  flex-wrap: wrap;
}
.info-label p:nth-child(2n-1) {
  width: 50%;
}
.info-label p:nth-child(2n) {
  width: 50%;
}
.qr-wrapper {
  position: relative;
}
.qr-wrapper .hover-img {
  display: none;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 40px;
  width: 160px
}
.qr-wrapper:hover .hover-img {
  display: block;
}
@media screen and (max-width: 770px) {
  .cooperation {
    margin: 0 5px;
    margin-top: 15px;
    font-size: 14px;
    line-height: 30px;
  }
  .cooperation .qr-img {
    width: 70px
  }
  .contact .logo-img {
    width: 145px;
  }
  .contact {
    width: 155px;
  }
  .info {
    line-height: 30px;
    font-size: 12px;
  }
  
  .inside {
    width: calc(100% - 25px);
  }
  .info-label p:nth-child(2n-1) {
    width: 48%;
  }
  .info-label p:nth-child(2n) {
    width: 52%;
  }
}

@media screen and (max-width: 767px) {
  .container.inside {
    display: none;
  }
}
</style>